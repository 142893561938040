@import '../../vars.scss';

.section-how-it-works {
    background-image:
        url(/assets/010_ilsutracionfondo.svg),
        url(/assets/002_Banner.jpg);
    background-position: right, top left;
    background-repeat: no-repeat;
    background-size: contain, cover;
    padding: 5rem 0rem;

    h1, h2, p {
        color: $white;
    }

    .finance-header {
        color: $white;
        text-align: center;
        
        &_logo {
            width: 20rem;
            margin-top: 1.2rem;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 60% 40%;
        width: 100%;
        margin-top: 2rem;

        &-left {
            .rel {
                position: relative;
            }

            .abs {
                position: absolute;
                border: 1px solid $white;
                background-color: $white;
                width: 1px;
                top: 4.5rem;
                left: 5.5rem;
                display: block;

                &-1 {
                    height: 60px;
                }

                &-2 {
                    height: 85px;
                }

                &-3 {
                    height: 90px;
                }
            }
        }

        &-right {
            padding: 4rem 2rem;
            background-image: url(/assets/009_screen.png);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: 0% 50%;
            
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: $sm) {
    .section-how-it-works {
        padding: 2rem 0;
        background-image: url(/assets/002_Banner.jpg);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;

        .content {
            grid-template-columns: 1fr;

            &-left {
                .abs {
                    display: none;
                }
            }
 
            &-right {
                display: none;
            }
        }
    }
}

@media (min-width: $sm ) and (max-width: $md) {

    .section-how-it-works {
        padding: 2rem 0;
        background-image: url(/assets/002_Banner.jpg);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;

        .content {
            grid-template-columns: 1fr;

            &-left {
                .abs {
                    display: none;
                }
            }
 
            &-right {
                display: none;
            }
        }
    }
}