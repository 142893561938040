@import '../../vars.scss';

.Card {
    padding: 2rem;
    form {

        & > :first-child {
            margin-bottom: 30px;
        }
    }

    .MuiButton-root {
        padding: 0.5rem 1.2rem;
        text-transform: uppercase;
        width: 100%;
    }

    button {
        margin: 1rem 0;
    }

    .center {
        text-align: center;
        margin: 20px;
        
        p {
            font-size: 1rem;
        }

        & > :first-child {
            margin-bottom: 20px;
        }

        span {
            font-weight: 600;
            color: $finance-aqua;
        }
    }
}
