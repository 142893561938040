@import './vars.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'LouisGeorgeCafe', sans-serif !important;
  -webkit-font-smoothing: 'LouisGeorgeCafe', sans-serif;
  -moz-osx-font-smoothing: 'LouisGeorgeCafe', sans-serif;
}

@media (min-width: $xs) {
  html, body { 
    font-size: 12px;
  }
}

@media (min-width: $sm) {
  html, body { 
    font-size: 14px;
  }
}

@media (min-width: $md) {
  html, body { 
    font-size: 16px;
  }
}

@media (min-width: $lg) {
  html, body { 
    font-size: 16px;
  }
}

@media (min-width: $xl) {
  html, body { 
    font-size: 20px;
  }
}
