@import '../../vars.scss';

.section-header {
    background-image: url(/assets/001_Banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    .finance-container {
        padding: 12.5rem 0 6.5rem 12rem;
        color: white;

        h1 {
            max-width: 50rem;
            color: $white;
        }

        p {
            color: $white;
        }

        .text {
            margin: 3rem 0;
        }
    }

    a {
        text-decoration: none;
    }
}

@media (max-width: $md) {

    .section-header {
        min-height: 40vh;
        
        .finance-container {
            padding: 8rem 2rem;
            
            .text {
                margin: 3rem 0;
            }
        }
    }
}
