@import './vars.scss';

section {

    .finance-container {
        max-width: 100rem;
        margin: auto;
    }

    h1 {
        font-size: 2.5rem;
        letter-spacing: 1px;
    }

    h2 {
        font-size: 2rem;
    }

    h1,
    h2 {
        color: $finance-dark-purple;
    }

    p {
        font-size: 1.3rem;
        color: $finance-light-grey;
    }

    form {
        .MuiInputBase-root {
            color: $white;
        }

        .MuiInputBase-input {
            font-family: 'LouisGeorgeCafe', sans-serif;
            font-weight: 300;
            color: $white;
            padding: 6px 0 25px 15px;
            font-size: 1.5rem;
        }

        .MuiInput-underline:before {
            transition: $white 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-bottom: 1px solid $white;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 2px solid $white;
        }

        // cambia color borde
        .MuiInput-underline:after {
            border-bottom: 2px solid $white;
        }

        .MuiInputLabel-formControl {
            margin-left: 1rem;
        }

        .MuiInputLabel-shrink {
            transform: translate(0, -5px) scale(0.75);
            transform-origin: top left;
        }

        .MuiFormLabel-root {
            color: $white !important;
            padding: -5px;
            font-size: 1.5rem;
            font-family: 'LouisGeorgeCafe', sans-serif;
            font-weight: 400;
            line-height: 2.5;
            letter-spacing: 0.00938em;
        }

        .MuiSvgIcon-root {
            fill: $finance-aqua;
            margin-top: -3px;
        }

        .w-100 {
            width: 100%;
        }
    }

    .MuiButton-containedPrimary {
        color: $finance-aqua;
        background-color: $finance-yellow;
        font-size: 1.3rem;
        border-radius: 1.5rem;
    }

    .MuiButton-root {
        padding: 0.9rem 1.2rem;
        text-transform: none;
    }

    .MuiButton-containedPrimary:hover {
        background-color: $finance-hover-back;
        color: $finance-yellow;
    }
}
