@import '../../vars.scss';

.work-info {
    display: flex;
    padding: 1.5rem 0;
    color: $white;

    .left-content {
        width: 20%;
        display: flex;
        justify-content: center;

        .number {
            color: $finance-yellow;
            
        }
    }
    
    .right-content {
        width: 80%;
        padding-right: 2.5rem;
        .content-header {
            font-size: 2rem;
        }

        .content-text {
            font-size: 2rem;
            
            span {
                font-weight: 600;
            }
        }
    }
}
