@import '../../vars.scss';

.section-capital {
    max-width: 1550px;
    margin: auto;
    padding: 6rem 0;
    
    .capital-header {
      margin: auto;
      max-width: 30rem;
      text-align: center;
      padding-bottom: 6rem;
    }
    
    .info-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
      row-gap: 6rem;
      column-gap: 4rem;
      
      .capital-info {
        display: flex;
        
        .left-content {
          width: calc(20% - 10px);
          margin-right: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .right-content {
          width: 80%;
        }
      }
    }
}

@media (max-width: $md) {
  .section-capital {
    padding: 2rem 2rem 4rem;

    .capital-header {
      margin: 2rem auto;
      padding-bottom: 3rem;
    }

    .info-container {
      row-gap: 2rem;
    }
  }
}
