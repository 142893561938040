@import '../../vars.scss';

.section-footer {
    background-color: $footer-bck;
    .finance-container {
        border-bottom: 1px solid $white;
        padding: 3rem 2.5rem;

        .contact-info {
            h2 {
                text-align: center;
            }

            h2, p {
                color: $white;
            }

            #schedule {
                margin-top: 2rem;
            }


            #address {
                margin: 2rem 0;
            }

            .logos {
                text-align: center;

                .finance-logo {
                    width: 100%;
                    max-width: 25rem;
                    margin-bottom: 2rem;
                }

                .social {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    max-width: 20rem;
                    margin: auto;

                    &-logo {
                        width: 100%;
                        max-width: 4rem;
                    }
                }
            }
        }

        .contact-form {
            text-align: center;

            div {
                margin-top: 1.5rem;
            }

            button {
                margin-top: 2rem;
            }

            .MuiButton-root {
                padding: 0.9rem 2.5rem;
                text-transform: uppercase;
            }
        }
    }

    .copyright {
        text-align: center;
        text-transform: uppercase;
        padding: 1rem 0;
        color: $white;
    }
}

@media (min-width: $md) {
    .section-footer {
        .finance-container {
            display: grid;
            grid-template-columns: 45% 55%;

            .contact-info {
                margin-left: 5rem;
                
                h2 {
                    font-weight: normal;
                    max-width: 26rem;
                }
        
                .logos {
                    text-align: left;
    
                    .finance-logo {
                        width: 100%;
                        max-width: 15rem;
                        margin-bottom: 1rem;
                    }
    
                    .social {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        max-width: 12rem;
                        margin: 0;
    
                        &-logo {
                            width: 100%;
                            max-width: 2rem;
                        }
                    }
                }
            }

            .contact-form {
                text-align: left;

                form {
                    div:first-child {
                        margin-top: 0;
                    }

                    .MuiInputBase-input {
                        font-size: 1.3rem;
                    }

                    .MuiFormLabel-root {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}
