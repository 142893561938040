@import '../../vars.scss';

.navigation {

    &__logo {
        width: 100%;
        max-width: 13rem;
        display: block;
        position: absolute;
        top: 2rem;
        left: 2rem;
        
    }

    &__info {
        position: absolute;
        top: 2.6rem;
        right: 8rem;
        display: flex;
        align-items: center;
        color: $white;
        font-size: 1.2rem;
        

        .info-icon {
            width: 2rem;
            margin-right: 1rem;
        }
    }

    &__checkbox {
        display: none;
    }

    &__button {
        border: 1px solid white;
        height: 3.5rem;
        width: 3.5rem;
        position: fixed;
        top: 2rem;
        right: 2rem;
        border-radius: 50%;
        z-index: 12;
        box-shadow: 0 1rem 3rem rgba(black, .1);
        text-align: center;
        cursor: pointer;
    }

    &__background {
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
        position: fixed;
        top: -13.5rem;
        right: 3.5rem;
        background-color: $finance-aqua;
        z-index: 10;

        transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
    }

    &__nav {
        height: 100vh;
        position: fixed;
        top: 0;
        left: -100rem;
        z-index: 11;

        opacity: 0;
        width: 0;
        transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;

        width: 100%;
    }

    &__item {
        margin: 1rem;
    }

    &__link {
        &:link,
        &:visited {
            display: inline-block;
            font-size: 2.5rem;
            font-weight: 600;
            padding: 1rem 2rem;
            color: $finance-yellow;
            text-decoration: none;
            text-transform: uppercase;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $finance-yellow 50%);
            background-size: 220%;
            transition: all .4s;

            span {
                margin-right: 1rem;
                display: inline-block;
            }
        }

        &:hover,
        &:active {
            color: $finance-aqua;
            background-position: 100%;
            transform: translateX(1rem);
        }
        
    }

    //functionality

    &__checkbox:checked ~ &__background {
        transform: scale(90);
    }

    &__checkbox:checked ~ &__nav {
        opacity: 1;
        left: 0;
        width: 100%;
    }

    //icon
    &__icon {
        position: relative;
        margin-top: 1.7rem;
        &,
        &::before,
        &::after {
            width: 1.5rem;
            height: 2px;
            background-color: $finance-yellow;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s;
        }

        //line top
        &::before { 
            top: -0.5rem;
        }
        //line Bottom
        &::after { 
            top: 0.5rem;
        }
    }

    &__button:hover &__icon::before {
        top: -0.7rem;
    }

    &__button:hover &__icon::after {
        top: 0.7rem;
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon::before {
        top: 0;
        transform: rotate(45deg);
    }

    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(-45deg);
    }
}

@media (max-width: $md) {
    .navigation {
        &__logo {
            display: none;
        }

        &__info {
            display: none;
        }

        &__list {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            list-style: none;
            text-align: center;
    
            width: 100%;
        }
    }
}
