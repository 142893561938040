.list-container {
    display: flex;

    .icon {
        margin-right: 2rem;
    }

    .icon > img {
        width: 1rem;
    }
}