@import '../../vars.scss';

main {
    .flex-view {
        display: flex;
        min-height: 100vh;
        
        .left-side {
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            border-right: 10px solid #00e0b5;
        
            .finance-logo {
                width: 100%;
                max-width: 25rem;
                margin: 3rem;
            }

            .welcome-container {
                text-align: right;
                margin-right: 3rem;

                h1{
                    color: $finance-aqua;
                    font-size: 3rem;
                    text-transform: uppercase;
                }

                .welcome-text {
                    color: $finance-light-grey;
                    font-size: 1.5rem;
                }
            }

            .welcome-footer {
                margin: 3rem;
                text-transform: uppercase;
                font-weight: 300;
            }
        }

        .right-side {
            background-image: url(/assets/010_ilsutracionfondo.svg);
            background-repeat: no-repeat;
            background-color: $finance-aqua;
            background-position: center;
            justify-content: center;
            width: 50%;

            .finance-logo {
                display: none;
            }

            .welcome-container {
                display: none;
            }

            .welcome-footer {
                display: none;
            }

            .login-container {
                align-self: center;
                min-width: 300px;
                max-width: 600px;
            }
        }
    }
}

@media (max-width: $md) {
    main {
        .flex-view {
            flex-direction: column;

            .left-side {
                display: none;
            }
    
            .right-side {
                width: 100%;
                
                .finance-logo {
                    display: block;
                    width: 100%;
                    max-width: 15rem;
                    margin: auto;
                }
    
                .welcome-container {
                    display: block;
                    text-align: center;
    
                    h1{
                        color: $finance-yellow;
                        font-size: 3rem;
                        text-transform: uppercase;
                    }
    
                    .welcome-text {
                        color: $white;
                        font-size: 1.5rem;
                    }
                }

                .welcome-footer {
                    margin: 1rem;
                    text-transform: uppercase;
                    font-weight: 300;
                    text-align: center;
                    display: block;
                }
            }
        }
    }
}
