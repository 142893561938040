$xl: 1920px;
$lg: 1280px;
$md: 960px;
$sm: 600px;
$xs: 0px;

$finance-yellow: #ffe600;
$finance-hover-back: #036b57;
$finance-aqua: #00aa9d;
$footer-bck: #00c1aa;
$finance-dark-purple: #11175e; //for headings
$finance-light-grey: #585959; // for text
$white: #fff;
