@import '../../vars.scss';

.section-begin {

    a {
        text-decoration: none;
    }

    padding: 3rem 2rem;
    .finance-container {
        .begin-info {
            text-align: center;
            margin: auto;

            h1 {
                margin-bottom: 3rem;
            }

            h2 {
                font-size: 1.8rem;
            }

            .table {
                &-heading {
                    margin: 2rem 0;
                }

                &-content {
                    max-width: 30rem;
                    margin: auto;

                    .list-container {
                        margin: 2rem 0;
                        text-align: left;
                    }
                }
            }

            .apply {
                h2 {
                    margin: 2rem 0;
                }

                button {
                    margin-bottom: 2rem;
                }

                p > span {
                    color: $finance-aqua;
                }
            }
        }

        .begin-image {
            display: none;
        }
    }
}

@media (min-width: $md) {
    .section-begin {
        padding: 0;
        .finance-container {
            display: grid;
            grid-template-columns: 65% 35%;
            grid-column-gap: 0;

            .begin-info {
                padding: 3rem 0;
                margin-left: 5rem;
                
                h1 {
                    text-align: left;
                    max-width: 27rem;
                    margin-top: 4rem;
                }
        
                .content {
                    display: grid;
                    grid-template-columns: 40% 53%;
                    grid-column-gap: 3rem;
                    margin-bottom: 6rem;
                    text-align: left;

                    .table {
                        &-content {
                            max-width: 40rem;
                            margin: auto;
        
                            .list-container {
                                margin: 2rem 0;
                                text-align: left;
                                max-width: 28rem;
                            }
                        }
                    }
                }
        
                .apply {
                    text-align: left;
                    .ready {
                        display: flex;
                        align-items: center;

                        div
                        h2 {
                            margin-right: 1rem;
                        }

                        .start-now {
                            button {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .begin-image {
                display: inline-block;
                padding: 4rem 2rem;
                background-image: url(/assets/003_Foto.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 0% 50%;
            }
        }
    }
}
