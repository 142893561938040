.capital-info {
    display: flex;

    .left-content {
        width: 25%;
        display: flex;
        justify-content: center;
    }
    
    .right-content {
        width: 75%;

        .content-header {
            margin-bottom: 1rem;
            font-size: 2rem;
        }

        .content-text {
            font-size: 2rem;
        }
    }
}
